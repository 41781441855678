<template>
  <div class="large-screen-container flex flex-col">
    <Header title="河南智慧林业综合平台"></Header>
    <div class="body flex">
      <div class="content">
        <div class="left">
          <div class="left-top">
            <div class="left-top-title">
              河南省森林资源统计
            </div>
            <div class="section">
              <div class="hqxx-section border-btm">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon"></i>
                  <span class="hqxx-section-label-title">河南省林地面积(万公顷)</span>
                </div>
                <div class="hqxx-sction-info">
                  <div class="hqxx-section-info-cell" v-for="(item,i) in drhqsArr" :key="i">
                    {{item != '-' ? item :''}}
                  </div>
                </div>
                <div class="pie-section">
                  <div class="pie">
                     <pie-chart :datas="pieDatasArr"></pie-chart>
                  </div>
                  <div class="tree-section">
                    <div class="tree-card">
                      <div class="tree-card-top">
                        河南省森林面积(万公顷)
                      </div>
                      <div class="tree-card-num">
                        403.18
                      </div>
                    </div>
                    <div class="tree-card">
                      <div class="tree-card-top">
                        河南省森林覆盖率
                      </div>
                      <div class="tree-card-num" style="color:#22D72F;">
                        24.14%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="hqxx-section park-section">
                <div class="hqxx-section-label">
                  <i class="hqxx-section-label-icon hqxx-section-label-icon-tree2"></i>
                  <span class="hqxx-section-label-title">各类型树木面积（万公顷）</span>
                </div>
                <div class="list-top">
                  <div class="index">
                    排名
                  </div>
                  <div class="label">
                    类型
                  </div>
                  <div style="flex:1;"></div>
                  <div class="num">
                    面积
                  </div>
                </div>
                <div class="percent-list">
                  <div class="percent-list-item" v-for="(item,i) in percentList" :key="i">
                    <div :class="['index','index-'+(i+1)]">
                      <span>{{i + 1}}</span>
                    </div>
                    <div class="label">
                      {{item.name}}
                    </div>
                    <div class="percent-bar">
                      <div class="cur" :style="`width:${item.percent}%;`"></div>
                    </div>
                    <div class="num">
                      {{item.numText}}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="left-section">
            <div class="left-section-title">
              图例
            </div>
            <div class="left-section-content">
              <div :class="['left-section-item', item.code]" v-for="(item ,i) in arr2" :key="i">
                <i class="icon"></i>
                {{item.name}}
              </div>
            </div>
          </div>
        </div>
        <div class="center">
          <MapChart :datas="mapDatas"></MapChart>
        </div>
        <div class="right">
        </div>
      </div>
      <div class="bottom ">
        <Navigation></Navigation>
      </div>
    </div>
  </div>
</template>

<script>
import flexs from "@/mixins/flexs.js";
import MapChart from "./components/map-chart";
import Header from '../../components/header'
import Navigation from '../../components/navigation'
import PieChart from './components/pie-chart'
export default {
  data() {
    return {
      mapDatas: {

      },
      pieDatas: [
          { value: 24.14, name: "河南省森林覆盖率" },
      ],
      percentArr:[{
        name: '森林公园',
        num: 21.02
      },
      {
        name: '湿地公园',
        num:  20.21
      },{
        name: '自然保护区',
        num: 18.03
      },{
        name: '风景名胜区',
        num: 15.58
      },{
        name: '地质公园',
        num: 14.23
      },{
        name: '地质公园1',
        num: 12.38
      },{
        name: '地质公园2',
        num: 11.28
      },{
        name: '地质公园3',
        num: 10.87
      }],
      drhqs: '520.74',
      arr2: [{
        name: '有林地',
        code: 'fjmsq'
      },{
        name: '疏林地',
        code: 'rbhd'
      },{
        name: '灌木林地',
        code: 'slgy'
      },{
        name: '未成林地',
        code: 'dzgy'
      },{
        name: '苗圃地',
        code: 'sdgy'
      },{
        name: '无立林地',
        code: 'wlld'
      },{
        name: '宜林地',
        code: 'yld'
      },{
        name: '林业辅助用地',
        code: 'lyfzyd'
      },]
    }
  },
  components: {
    MapChart,
    Header,
    Navigation,
    PieChart
  },
  computed: {
    pieDatasArr(){
      const _arr = [
        ...this.pieDatas,
        {
          value: 75.86 ,name: '其他'
        }
      ]
      return _arr
    },
    drhqsArr(){
      return this.convertToArr(this.drhqs)
    },
    percentList(){
      const _arr = [...this.percentArr]
      _arr.sort((a,b)=>{return b.num - a.num})
      const max = _arr[0].num
      this.percentArr.forEach(item => {
        item.percent = ((item.num / max)*100).toFixed(2)
        item.numText =  (item.num+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      });
      return this.percentArr
    }
  },
  methods: {
    convertToArr(_str){
      const maxLen = 8
      let _arr = Array.apply(null, Array(10)).map(function() { return '-'; });
      const _beginIndex = maxLen - _str.length
      _arr.splice(_beginIndex)
      _arr = [..._arr, ..._str.split('')]
      return _arr
    },
  },
  mixins: [flexs],
};
</script>
<style lang="scss">
@import "../../assets/css/index.scss";
</style>
<style lang="scss" scoped>
.large-screen-container {
  height: 100%;
  overflow: hidden;
  background: url('../../assets/images/ls-bg.png') center center no-repeat;
  background-size: 100% 100%;
  padding: 0 35px 39px 59px;
  position: relative;

  
  .body{
    height: calc(100% - 102px);
    width: calc(100% - 112px);
    position: absolute;
    left: 53px;
    top: 80px;
    display: flex;
    flex-direction: column;
    .content{
      flex: 1;
      display: flex;
      .left{
				text-align: left;
        width: 437px;
        .left-top{
					height: 682px;
          background: url('./images/left-top-bg.png') center center no-repeat;
          background-size: 100% 100%;
          position: relative;
          padding-top: 37px;
          display: flex;
          flex-direction: column;
          .left-top-title{
            left: 55px;
            top: 3px;    
						line-height: 1;
            width: 100%;
            text-align: left;
            position: absolute;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
					.hqxx-section{
						margin-top: 13px;
						margin-left: 35px;
						margin-right: 20px;
            &.border-btm{

              padding-bottom: 24px;
              border-bottom: 1px solid #1A3066;
            }
            .hqxx-sction-info{
              margin-top: 10px;
              display: flex;
              justify-content: space-between;
              padding-bottom: 22px;
              .hqxx-section-info-cell{
                width: 30px;
                height: 43px;
                background: #0D1732;
                border: 1px solid #2D80EC;
                line-height: 43px;
                font-family: PangMenZhengDao;
                font-weight: 400;
                font-size: 30px;
                color: #69FFFA;
                text-align: center;
              }
            }
            &.park-section{
              margin-top: 27px;
              flex: 1;
              flex-direction: column;

              .index{
                width: 33px;
                height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                text-align: center;
                margin-right: 24px;
                line-height: 17px;
              }

              .label{
                width: 82px;
                height: 17px;
                line-height: 17px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 16px;
                color: #D9EAF5;
                font-style: italic;
                margin-right: 24px;
                text-align: left;
              }
              .list-top{
                margin-top: 10px;
                display: flex;
                text-align: center;
                .num{
                  width: 78px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  font-style: italic;
                  line-height: 17px;
                }
                .percent{
                  width: 60px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  font-style: italic;
                  line-height: 17px;
                  text-align: left;
                }
              }
              .percent-list-item{
                margin-top: 14px;
                display: flex;
                align-items: center;
                &:first-of-type{
                  margin-top: 17px;
                }
                .index{
                  >span{
                    display: block;
                    width: 18px;
                    height: 18px;
                    line-height: 18px;
                    margin: 0 auto;
                    background: #152754;
                    border-radius: 2px;
                  }
                  &.index-1{
                    >span{
                      color: #1F2224;
                      background: #E4DE2F;
                    }
                  }
                  &.index-2{
                    >span{
                      color: #1F2224;
                      background: #E4DE2F;
                    }
                  }
                  &.index-3{
                    >span{
                      color: #1F2224;
                      background: #B9B9B9;
                    }
                  }
                }
                .percent-bar{
                  flex: 1;
                  height: 13px;
                  background: #0C1D3F;
                  border-radius: 2px;
                  overflow: hidden;
                  .cur{
                    height: 100%;
                    background-color: #4986D5;
                  }
                }
                .num{
                  width: 78px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  line-height: 17px;
                  text-align: center;
                }
                .percent{
                  width: 60px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #D9EAF5;
                  text-align: center;
                  line-height: 17px;
                }
              }
            }
					}
          .pie-section{
            display: flex;
            .pie{
              flex: 1;
            }
            .tree-section{
              display: flex;
              flex-direction: column;
              .tree-card{
                margin-top: 11px;
                width: 182px;
                height: 71px;
                background: url('./images/tree-card-bg.png') center center no-repeat;
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;
                text-align: center;
                .tree-card-top{
                  height: 17px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  font-size: 16px;
                  color: #FFFFFF;
                  line-height: 17px;
                }
                .tree-card-num{
                  height: 17px;
                  font-family: PangMenZhengDao;
                  font-weight: 400;
                  font-size: 26px;
                  color: #69FFFA;
                  line-height: 17px;
                }
              }
            }
          }
				}
        .hqxx-section-label{
          display: flex;
          align-items: center;
          .hqxx-section-label-icon{
            display: block;
            width: 24px;
            height: 24px;
            margin-right: 14px;
            background: url("./images/tree.png") center center no-repeat;
            background-size: 100% 100%;
            &.hqxx-section-label-icon-tree2{
              background: url("./images/tree2.png") center center no-repeat;
              background-size: 100% 100%;
            }
          }
          .hqxx-section-label-title{
            flex: 1;
            height: 17px;
            line-height: 1;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #FFFFFF;
            text-align: left;
          }
        }
        .left-section{
          background: url('./images/left-medium-bg.png') center center no-repeat;
          background-size: 100% 100%;
          padding: 24px;
          margin-top: 40px;
          .left-section-title{
            width: 98px;
            height: 24px;
            line-height: 24px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 24px;
            color: #D9EAF5;
            text-shadow: 0px 3px 0px rgba(2,13,37,0.3);
            font-style: italic;
            background: linear-gradient(0deg, rgba(100,206,255,0.71) 0%, rgba(250,255,255,0.71) 45.9228515625%, rgba(255,255,255,0.71) 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .left-section-content{
            display: flex;
            flex-wrap: wrap;
            .left-section-item{
              margin-top: 26px;
              width: 128px;
              padding-left: 25px;
              height: 16px;
              line-height: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              font-size: 16px;
              color: #D9EAF5;
              font-style: italic;
              position: relative;
              .icon{
                display: block;
                position: absolute;
                width: 12px;
                height: 12px;
                border-radius: 2px;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                background-color: #2E6ED9;
                &.rect::after{
                  content: '';
                  display: block;
                  width: 6px;
                  height: 6px;
                  background: #FFFFFF;
                  border-radius: 2px;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  margin: auto;

                }
              }
              &.fjmsq{
                .icon{
                  background: #38A800;
                }
              }
              &.zrbhd{
                .icon{
                  background: #AAFFFF;
                }
              }
              &.slgy{
                .icon{
                  background: #3ECDBD;
                }
              }
              &.dzgy{
                .icon{
                  background: #00AAFF;
                }
              }
              &.sdgy{
                .icon{
                  background: #155815;
                }
              }
              &.wlld{
                .icon{
                  background: #FEA6CC;
                }
              }
              &.yld{
                .icon{
                  background: #FFDBBE;
                }
              }
              &.lyfzyd{
                .icon{
                  background: #CCCCCC;
                }
              }
            }
          }
        }
      }
      .right{
        width: 437px;
      }
      .center{
        flex: 1;
      }
    }
    .bottom{
      height: 50px;
      margin-top: 13px;
    }
  }
}
</style>