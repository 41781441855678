<template>
  <div class="map-chart-container">
    <div class="map" :id="id">
      <!-- 地图 -->
    </div>
  </div>
</template>
<script>
import mapJson from "@/assets/mapJson/henansheng.json";
import cityMap from '@/assets/mapJson/city'
import point1 from '@/assets/images/point2.jpg'
import point2 from '@/assets/images/point1.jpg'
import mapBg from '@/assets/images/map-bg.jpg'
// import {mapBg} from '@/assets/js/mapBg'
export default {
  name: "MapChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "map-chart-" + this.$utils.uuid();
      },
    },
  },
  data() {
    return {
      mapData: [
        {
          name: "濮阳市",
          code: "pys",
          value: 20057,
          finish: 2000,
          doing: 234,
        },
        {
          name: "安阳市",
          code: "ays",
          value: 1547,
          finish: 12000,
          doing: 2344,
        },
        {
          name: "鹤壁市",
          code: "hbs",
          value: 31686,
          finish: 2200,
          doing: 555,
        },
        {
          name: "新乡市",
          code: "xxs",
          value: 6992,
          finish: 200,
          doing: 999,
        },
        {
          name: "焦作市",
          code: "jzs",
          value: 44045,
          finish: 1000,
          doing: 233344,
        },
        {
          name: "济源市",
          code: "jys",
          value: 40689,
          finish: 4000,
          doing: 24,
        },
        {
          name: "三门峡市",
          code: "smxs",
          value: 37659,
          finish: 20030,
          doing: 10,
        },
        {
          name: "洛阳市",
          code: "lys",
          value: 45180,
          finish: 202,
          doing: 2340,
        },
        {
          name: "郑州市",
          code: "zzs",
          value: 55204,
          finish: 20,
          doing: 234,
        },
        {
          name: "开封市",
          code: "kfs",
          value: 21900,
          finish: 123,
          doing: 234,
        },
        {
          name: "商丘市",
          code: "sqs",
          value: 4918,
          finish: 4000,
          doing: 234,
        },
        {
          name: "平顶山市",
          code: "pdss",
          value: 3881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "许昌市",
          code: "xcs",
          value: 2881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "周口市",
          code: "zks",
          value: 9881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "漯河市",
          code: "lhs",
          value: 1881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "南阳市",
          code: "nys",
          value: 881,
          finish: 9000,
          doing: 234,
        },
        {
          name: "驻马店市",
          code: "zmds",
          value: 1981,
          finish: 9000,
          doing: 234,
        },
        {
          name: "信阳市",
          code: "xys",
          value: 2881,
          finish: 9000,
          doing: 234,
        },
      ],
      scatter3DArr:[
        {name: '洛阳市', value: [113.26436, 34.342896], num: 200000},
        {name: '周口市', value: [113.649653,33.620357], num: 100},
        {name: '1', value: [114.649653,33.620357], num: 500},
        {name: '2', value: [112.649653,33.620357], num: 500},
        {name: '3', value: [112.649653,32.620357], num: 500},
        {name: '5', value: [114.649653,33.620357], num: 500},
        {name: '6', value: [113.649653,32.620357], num: 4444},
        {name: '7', value: [111.649653,33.920357], num: 333},
      ],
      option:{
        tooltip: {
          show: false,
        },
        geo3D:{
          type: 'map3D',
          name: '地图',
          // 相对于父容器比例
          center: ['50%', '50%'],
          selectedMode: 'single', // 地图高亮单选
          regionHeight: 6, // 地图高度
          map: 'henan',
          viewControl: {
            // 缩放大小，数值越大，地图越小
            distance: 145,
            // 上下倾斜角度
            alpha: 65,
            // 左右倾斜角度
            beta: 3,
            rotateSensitivity: 0,
            autoRotate:false,
            zoomSensitivity: false,
          },
          label: {
            show: false, // 是否显示名字
            color: '#fff', // 文字颜色
            fontSize: '12px', // 文字大小
            formatter: (params)=>{
              const _cur = this.mapData.find(item => item.name == params.name) || ''
              return `{city|${params.name}:}{num|${_cur.value || 0}}`
            },
            backgroundColor: 'rgba(5,4,41,0.79)',
            borderColor: '#4986D5',
            borderWidth: 1,
            borderRadius: 5,
            padding: 10,
            rich: {
              city: {
                color: '#D9EAF5',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              num: {
                color: '#2EED32',
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
          shading: "realistic",
          realisticMaterial: { 
            detailTexture: mapBg,  // 地图表面贴图  
          },
          itemStyle: {
            color: 'rgba(24,144,255,1)', // 地图背景颜色
            borderWidth: 1, // 分界线wdith
            borderColor: '#fff', // 分界线颜色
            opacity: 0.92,
            borderType: "dashed",
          },
          emphasis: {
            label: {
              show: true, // 是否显示高亮
              textStyle: {
                color: '#fff' // 高亮文字颜色
              }
            },
            itemStyle: {
              color: '#00baff', // 地图高亮颜色
              borderWidth: 10, // 分界线wdith
              borderColor: '#6BECF5' // 分界线颜色
            }
          },
          left: '0',
          // top: '-20px',
          zoom: 1,
          // data: [],
          light: {
            main: {
              color: '#fff',
              intensity: 1.8,
              shadow: true,
              shadowQuality: 'high',
              alpha: 25, //
              beta: 20
            },
            ambient: {
              color: '#fff',
              intensity: 0.6
            }
          }
        },
        series: [
          {
            zlevel: 5,
            type: 'map3D',
            name: '地图',
            // 相对于父容器比例
            center: ['50%', '50%'],
            selectedMode: 'single', // 地图高亮单选
            regionHeight: 6, // 地图高度
            map: 'henan',
            viewControl: {
              // 缩放大小，数值越大，地图越小
              distance: 145,
              // 上下倾斜角度
              alpha: 65,
              // 左右倾斜角度
              beta: 3,
              rotateSensitivity: 0,
              autoRotate:false,
              zoomSensitivity: false,
            },
            label: {
              show: false, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
            },
            itemStyle: {
              color: 'rgba(24,144,255,1)', // 地图背景颜色
              borderWidth: 0, // 分界线wdith
              borderColor: '#fff', // 分界线颜色
              opacity: 0,
              borderType: "dashed",
            },
            emphasis: {
              label: {
                show: false, // 是否显示高亮
                textStyle: {
                  color: '#fff' // 高亮文字颜色
                }
              },
              itemStyle: {
                color: '#00baff', // 地图高亮颜色
                borderWidth: 0, // 分界线wdith
                opacity: 0,
                borderColor: '#6BECF5' // 分界线颜色
              }
            },
            left: '0',
            // top: '-20px',
            zoom: 1,
            // data: [],
            light: {
              main: {
                color: '#fff',
                intensity: 1,
                shadow: true,
                shadowQuality: 'high',
                alpha: 25, //
                beta: 20
              },
              ambient: {
                color: '#fff',
                intensity: 0.6
              }
            }
          },
          {
            zlevel: 6,
            type: 'scatter3D',
            coordinateSystem: 'geo3D', // 指定坐标系为 3D 地理坐标系
            data: [],
            label: {
              show: true, // 是否显示名字
              color: '#fff', // 文字颜色
              fontSize: '12px', // 文字大小
              formatter: ({data})=>{
                if(data.num > 10000) {
                  return `{point2|}`
                }
                return `{point1|}`
              },
              padding: 10,
            rich: {
              point1: {
                width: 40,
                height: 40,
                backgroundColor:{
                  image:point1
                } ,
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              },
              point2: {
                width: 40,
                height: 40,
                backgroundColor:{
                  image:point2
                } ,
                borderRadius: 15,
                padding: [0, 0, 0, 10],
                fontFamily: 'Microsoft YaHei',
                fontWeight: 400,
                fontSize: '16px',
                fontStyle: 'italic'
              }
            }
          },
            emphasis: {
                label: {show: true}
            },
            // 其他散点图配置
          },
        ]
      },
      chart: null,
      pointer: 0,
      timer: null,
    };
  },
  watch: {
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {

    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.$echarts.registerMap("henan", mapJson);
    if(cityMap){
      Object.keys(cityMap).forEach(key => {
        this.$echarts.registerMap(key, cityMap[key]);
      })
    }
      this.draw({
            data: this.mapData,
            scatter3DArr: this.scatter3DArr
          })
    });
  },
  methods: {
    initEvent() {
      this.chart.on("click", ({data ={} }) => {
        if(data.code){
          this.option.series[0].map = data.code
          this.option.series[1].data = []
          this.option.geo3D.map = data.code
          this.chart.dispose();
          const chartDom = document.getElementById(this.id);
          this.chart = this.$echarts.init(chartDom);
          this.draw()
        }
      });

       this.chart.getZr().on('click', params => {
        if(params.target) {
          //画布非空白区
          return;
        } else {
          if(this.option.series[0].map != 'henan'){
            this.option.series[0].map = 'henan'
            this.option.geo3D.map = 'henan'
            this.chart.dispose();
            const chartDom = document.getElementById(this.id);
            this.chart = this.$echarts.init(chartDom);
            this.draw({
              data: this.mapData,
              scatter3DArr: this.scatter3DArr
            })
          }
        }
      });
    },
    draw(params = {}) {
      const {data =[], scatter3DArr = []} = params
      this.option.series[0].data = data;
      this.option.series[1].data = scatter3DArr
      this.chart.setOption(this.option, true);
      this.initEvent()
    },
  },
};
</script>
<style lang="scss" scoped>
.map-chart-container,
.map {
  position: relative;
  width: 100%;
  height: 100%;
}
.title{
  width: 149px;
  height: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 20px;
  color: #E7F8FB;
  line-height: 20px;
  position: absolute;
  left: 62px;
  top: 30px;
  padding-left: 13px;
  white-space: nowrap;
  &::before{
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 5px;
    height: 5px;
    background: #6DFF58;
    border-radius: 50%;
  }

}
@keyframes cycle
{
    from {
      width: 15px;
      height: 15px;
    }
    to {
      width: 50px;
      height: 50px;
    }
}
.map-chart-container {
  position: relative;
}
</style>