<template>
  <div class="pie-chart-container" :id="id">
    <!-- 饼状图 -->
  </div>
</template>
<script>
export default {
  name: "PieChart",
  props: {
    id: {
      type: String,
      default: function () {
        return "pie-chart-" + this.$utils.uuid();
      },
    },
    chartOpt: {
      type: Object,
    },
    datas: {
      type: Array,
    },
  },
  data() {
    return {
      option: {
        title: {
          show: false,
          text: '本年度\n报警类型统计\n(起)',
          textStyle: {
            fontFamily: 'Microsoft YaHei',
            fontWeight: 400,
            fontSize: '14px',
            color: '#fff',
          },
          textAlign: 'center',
          left: '50%',
          top: '50%'
        },
        tooltip: {
          show: false,
        },
        color: ['#22D72F', 'transparent'],
        legend: {
          show: false,
          y: "center",
          align: "left",
          right: "5%",
          itemGap: 15,
          orient: "vertical",
          icon: "circle",
          itemHeight: 8,
          textStyle: {
            color: "#ffffff",
            fontSize: "12px",
          },
          itemStyle: {
            borderColor: "transparent",
          },
        },
        series: [
          {
            name: '报警类型统计',
            type: 'pie',
            radius: [40, 60],
            center: ['49%', '50%'],
            // roseType: 'area',
            itemStyle: {
              borderRadius: 0
            },
            label:{
              show: false,
              formatter: '{b}\n{c}|{d}%',
              fontWeight: 400,
              fontSize: '18px',
              color: '#FFFFFF',
            },
            data: []
          },
          {
            name: '报警类型统计',
            type: 'pie',
            radius: [40, 50],
            center: ['49%', '50%'],
            // roseType: 'area',
            itemStyle: {
              borderRadius: 0
            },
            label:{
              show: false,
              formatter: '{b}\n{c}|{d}%',
              fontWeight: 400,
              fontSize: '18px',
              color: '#FFFFFF',
            },
            data: [],
            emptyCircleStyle: {
              color: '#142756'
            }
          }
        ],
      },
      chart: null,
      timer: null,
      pointer: 0,
    };
  },
  mounted() {
    const chartDom = document.getElementById(this.id);
    this.chart = this.$echarts.init(chartDom);
    this.draw();
  },
  watch: {
    datas: {
      handler: function (val) {
        if (val) {
          this.draw();
        }
      },
      deep: true,
    },
  },
  computed: {
    dataIndexArr() {
      return this.datas.map((item, index) => {
        return index;
      });
    },
  },
  methods: {
    draw() {
      this.option.series[0].data = this.datas;
      this.chart.setOption(this.option);
    },
  },
};
</script>
<style lang="scss" scoped>
.pie-chart-container {
  width: 100%;
  // margin-left: -20%;
  height: 100%;
  position: relative;
}
</style>